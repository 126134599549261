// general icons
import SvgBath from '@/common/svgs/custom/dist/common/bath.svg';
import SvgBasin from '@/common/svgs/custom/dist/common/basin.svg';
import SvgFurniture from '@/common/svgs/custom/dist/common/furniture.svg';
import SvgMirror from '@/common/svgs/custom/dist/common/mirror.svg';
import SvgTap from '@/common/svgs/custom/dist/common/tap.svg';
import SvgHeart from '@/common/svgs/custom/dist/common/heart.svg';
import SvgShoppingBag from '@/common/svgs/custom/dist/common/shopping-bag.svg';
import SvgMessage from '@/common/svgs/custom/dist/common/message.svg';
import SvgTooltip from '@/common/svgs/custom/dist/common/tooltip.svg';
import SvgConfigurator from '@/common/svgs/custom/dist/common/configurator.svg';

// one up drawer
import SvgoneUpDrawerOpen from '@/common/svgs/custom/dist/one-up/one-up-drawer-open.svg';
import SvgoneUpDrawerOpenIntegrated from '@/common/svgs/custom/dist/one-up/one-up-drawer-open-integrated.svg';

// aure
import SvgAureOneFront from '@/common/svgs/custom/dist/aure/aure-one-front.svg';
import SvgAureOpenCompartment from '@/common/svgs/custom/dist/aure/aure-open-compartment.svg';
import SvgAureSideBySide from '@/common/svgs/custom/dist/aure/aure-side-by-side.svg';
import SvgAureTwoFronts from '@/common/svgs/custom/dist/aure/aure-two-fronts.svg';

// lax drawer
import SvgLaxDrawerOpen from '@/common/svgs/custom/dist/lax/lax-drawer-open.svg';
import SvgLaxDrawerTwoOpen from '@/common/svgs/custom/dist/lax/lax-drawer-two-open.svg';
import SvgLaxDrawerOpenIntegrated from '@/common/svgs/custom/dist/lax/lax-drawer-open-integrated.svg';

// tremezzo drawer
import SvgTremezzoDrawerOpen from '@/common/svgs/custom/dist/tremezzo/tremezzo-drawer-open.svg';
import SvgTremezzoDrawerOpenIntegrated from '@/common/svgs/custom/dist/tremezzo/tremezzo-drawer-open-integrated.svg';

// tremezzo
import SvgTremezzo63_01 from '@/common/svgs/custom/dist/tremezzo/60-90/63,4.svg';
import SvgTremezzo73_01 from '@/common/svgs/custom/dist/tremezzo/60-90/73,4.svg';
import SvgTremezzo83_01 from '@/common/svgs/custom/dist/tremezzo/60-90/83,4.svg';

import SvgTremezzo93_01 from '@/common/svgs/custom/dist/tremezzo/90-115/93,4.svg';
import SvgTremezzo98_01 from '@/common/svgs/custom/dist/tremezzo/90-115/98,4_cm_=_60_+_35.svg';
import SvgTremezzo103_01 from '@/common/svgs/custom/dist/tremezzo/90-115/103,4_cm.svg';
import SvgTremezzo108_01 from '@/common/svgs/custom/dist/tremezzo/90-115/108,4_cm_=_60_+_45.svg';
import SvgTremezzo108_02 from '@/common/svgs/custom/dist/tremezzo/90-115/108,4_cm_=_70_+_35.svg';

import SvgTremezzo118_01 from '@/common/svgs/custom/dist/tremezzo/115-130/118,4_cm_=_70_+_45.svg';
import SvgTremezzo118_02 from '@/common/svgs/custom/dist/tremezzo/115-130/118,4_cm_=_80_+_35.svg';
import SvgTremezzo123_01 from '@/common/svgs/custom/dist/tremezzo/115-130/123,4_cm_=_60_+_60.svg';
import SvgTremezzo123_02 from '@/common/svgs/custom/dist/tremezzo/115-130/123,4_cm.svg';
import SvgTremezzo128_01 from '@/common/svgs/custom/dist/tremezzo/115-130/128,4_cm_=_80_+_45.svg';
import SvgTremezzo128_02 from '@/common/svgs/custom/dist/tremezzo/115-130/128,4_cm_=_90_+_35.svg';

import SvgTremezzo138_01 from '@/common/svgs/custom/dist/tremezzo/130-145/138,4_cm_=_90_+_45.svg';
import SvgTremezzo138_02 from '@/common/svgs/custom/dist/tremezzo/130-145/138,4_cm_=_100_+_35.svg';
import SvgTremezzo143_01 from '@/common/svgs/custom/dist/tremezzo/130-145/143,4_cm_=_70_+_70.svg';
import SvgTremezzo143_02 from '@/common/svgs/custom/dist/tremezzo/130-145/143,4_cm_=_70_+35_+_35.svg';

import SvgTremezzo148_01 from '@/common/svgs/custom/dist/tremezzo/145-165/148,4_cm_=_100_+_45.svg';
import SvgTremezzo151_01 from '@/common/svgs/custom/dist/tremezzo/145-165/151,8_cm_=_60_+_28,4_+_60.svg';
import SvgTremezzo158_01 from '@/common/svgs/custom/dist/tremezzo/145-165/158,4_cm_=_60_+_35_+_60.svg';
import SvgTremezzo158_02 from '@/common/svgs/custom/dist/tremezzo/145-165/158,4_cm_=_120_+_35.svg';
import SvgTremezzo163_01 from '@/common/svgs/custom/dist/tremezzo/145-165/163,4_cm_=_80_+_80.svg';

import SvgTremezzo168_01 from '@/common/svgs/custom/dist/tremezzo/165-175/168,4_cm_=_60_+_45_+_60.svg';
import SvgTremezzo168_02 from '@/common/svgs/custom/dist/tremezzo/165-175/168,4_cm_=_120_+_45.svg';
import SvgTremezzo171_01 from '@/common/svgs/custom/dist/tremezzo/165-175/171,8_cm_=_70_+_28,4_+_70.svg';
import SvgTremezzo171_02 from '@/common/svgs/custom/dist/tremezzo/165-175/171,8_cm_=_70_+_35_+_28,4_+_35.svg';

import SvgTremezzo178_01 from '@/common/svgs/custom/dist/tremezzo/175-185/178,4_cm_=_70_+_35_+_70.svg';
import SvgTremezzo178_02 from '@/common/svgs/custom/dist/tremezzo/175-185/178,4_cm_=_70_+_70_+_35.svg';
import SvgTremezzo181_01 from '@/common/svgs/custom/dist/tremezzo/175-185/181,8_cm_=_80_+_35_+_28,4_+_35.svg';
import SvgTremezzo183_01 from '@/common/svgs/custom/dist/tremezzo/175-185/183,4_cm_=_60_+_60_+_60.svg';
import SvgTremezzo183_02 from '@/common/svgs/custom/dist/tremezzo/175-185/183,4_cm_=_90_+_45_+_45.svg';
import SvgTremezzo183_03 from '@/common/svgs/custom/dist/tremezzo/175-185/183,4_cm_=_90_+_90.svg';

import SvgTremezzo188_01 from '@/common/svgs/custom/dist/tremezzo/185-195/188,4_cm_=_70_+_45_+_70.svg';
import SvgTremezzo188_02 from '@/common/svgs/custom/dist/tremezzo/185-195/188,4_cm_=_70_+_70_+_45.svg';
import SvgTremezzo191_01 from '@/common/svgs/custom/dist/tremezzo/185-195/191,8_cm_=_70_+_45_+_28,4_+_45.svg';
import SvgTremezzo191_02 from '@/common/svgs/custom/dist/tremezzo/185-195/191,8_cm_=_80_+_28,4_+_80.svg';
import SvgTremezzo191_03 from '@/common/svgs/custom/dist/tremezzo/185-195/191,9_cm_=_90_+_35_+_28,4_+_35.svg';

import SvgTremezzo198_01 from '@/common/svgs/custom/dist/tremezzo/195-205/198,4_cm_=_80_+_35_+_80.svg';
import SvgTremezzo198_02 from '@/common/svgs/custom/dist/tremezzo/195-205/198,4_cm_=_80_+_80_+_35.svg';
import SvgTremezzo201_01 from '@/common/svgs/custom/dist/tremezzo/195-205/201,8_cm_=_80_+_45_+_28,4_+_45.svg';
import SvgTremezzo201_02 from '@/common/svgs/custom/dist/tremezzo/195-205/201,8_cm_=_100_+_35_+_28,4_+_35.svg';
// import SvgTremezzo203_01 from '@/common/svgs/custom/dist/tremezzo/195-205/203,4_2_cm_=_70_+_60_+_70.svg';
import SvgTremezzo203_02 from '@/common/svgs/custom/dist/tremezzo/195-205/203,4_3_cm_=_70_+_70_+_60.svg';
import SvgTremezzo203_03 from '@/common/svgs/custom/dist/tremezzo/195-205/203,4_cm_=_70_+_60_+_70.svg';
import SvgTremezzo203_04 from '@/common/svgs/custom/dist/tremezzo/195-205/203,4_cm_=_100_+_100.svg';

import SvgTremezzo208_01 from '@/common/svgs/custom/dist/tremezzo/205-215/208,4_cm_=_80_+_45_+_80.svg';
import SvgTremezzo208_02 from '@/common/svgs/custom/dist/tremezzo/205-215/208,4_cm_=_80_+_80_+_45.svg';
import SvgTremezzo211_01 from '@/common/svgs/custom/dist/tremezzo/205-215/211,8_cm_=_60_+_28,4_+_60_+_60.svg';
import SvgTremezzo211_02 from '@/common/svgs/custom/dist/tremezzo/205-215/211,8_cm_=_60_+_60_+_28,4_+_60.svg';
import SvgTremezzo211_03 from '@/common/svgs/custom/dist/tremezzo/205-215/211,8_cm_=_90_+_28,4_+_90.svg';
import SvgTremezzo211_04 from '@/common/svgs/custom/dist/tremezzo/205-215/211,8_cm_=_90_+_45_+_28,4_+_45.svg';
import SvgTremezzo213_01 from '@/common/svgs/custom/dist/tremezzo/205-215/213,4_cm_=_70_+_70_+_70.svg';

import SvgTremezzo218_01 from '@/common/svgs/custom/dist/tremezzo/215-225/218,4_cm_=_90_+__90_+_35.svg';
import SvgTremezzo218_02 from '@/common/svgs/custom/dist/tremezzo/215-225/218,4_cm_=_90_+_35_+_90.svg';
import SvgTremezzo221_01 from '@/common/svgs/custom/dist/tremezzo/215-225/221,8_cm_=_35_+_60_+_28,4_+_60_+_35.svg';
import SvgTremezzo221_02 from '@/common/svgs/custom/dist/tremezzo/215-225/221,8_cm_=_100_+_45_+_28,4_+_45.svg';
import SvgTremezzo221_03 from '@/common/svgs/custom/dist/tremezzo/215-225/221,8_cm_=_120_+_35_+_28,4_+_35.svg';
import SvgTremezzo223_01 from '@/common/svgs/custom/dist/tremezzo/215-225/223,4_cm_=_80_+_60_+_80.svg';
import SvgTremezzo223_02 from '@/common/svgs/custom/dist/tremezzo/215-225/223,4_cm_=_80_+_80_+_60.svg';

import SvgTremezzo228_01 from '@/common/svgs/custom/dist/tremezzo/225-235/228,4_cm_=_35_+_60_+_35_+_60_+_35.svg';
import SvgTremezzo228_02 from '@/common/svgs/custom/dist/tremezzo/225-235/228,4_cm_=_90_+_45_+_90.svg';
import SvgTremezzo228_03 from '@/common/svgs/custom/dist/tremezzo/225-235/228,4_cm_=_90_+_90_+_45.svg';
import SvgTremezzo231_01 from '@/common/svgs/custom/dist/tremezzo/225-235/231,8_cm_=_80_+_60_+_28,4_+_60.svg';
import SvgTremezzo231_02 from '@/common/svgs/custom/dist/tremezzo/225-235/231,8_cm_=_100_+_28,4_+_100.svg';
import SvgTremezzo233_01 from '@/common/svgs/custom/dist/tremezzo/225-235/233,4_cm_=_80_+_70_+_80.svg';
import SvgTremezzo233_02 from '@/common/svgs/custom/dist/tremezzo/225-235/233,4_cm_=_80_+_80_+_70.svg';

import SvgTremezzo238_01 from '@/common/svgs/custom/dist/tremezzo/235-245/238_cm_=_100_+_35_+_100.svg';
import SvgTremezzo238_02 from '@/common/svgs/custom/dist/tremezzo/235-245/238,4_cm_=_100_+_100_+_35.svg';
import SvgTremezzo241_01 from '@/common/svgs/custom/dist/tremezzo/235-245/241,8_cm_=_35_+_70_+_28,4_+_70_+_35.svg';
import SvgTremezzo241_02 from '@/common/svgs/custom/dist/tremezzo/235-245/241,8_cm_=_70_+_28,4_+_70_+_70.svg';
import SvgTremezzo241_03 from '@/common/svgs/custom/dist/tremezzo/235-245/241,8_cm_=_70_+_70_+_28,4_+_70.svg';
import SvgTremezzo241_04 from '@/common/svgs/custom/dist/tremezzo/235-245/241,8_cm_=_90_+_60_+_28,4_+_60.svg';
import SvgTremezzo241_05 from '@/common/svgs/custom/dist/tremezzo/235-245/241,8_cm_=_120_+_45_+_28,4_+_45.svg';
import SvgTremezzo243_01 from '@/common/svgs/custom/dist/tremezzo/235-245/243,4_cm_=_80_+_80_+_80.svg';
import SvgTremezzo243_02 from '@/common/svgs/custom/dist/tremezzo/235-245/243,4_cm_=_90_+_60_+_90.svg';
import SvgTremezzo243_03 from '@/common/svgs/custom/dist/tremezzo/235-245/243,4_cm_=_90_+_90_+_60.svg';
import SvgTremezzo243_04 from '@/common/svgs/custom/dist/tremezzo/235-245/243,4_cm_=_120_+_60_+_60.svg';
import SvgTremezzo243_05 from '@/common/svgs/custom/dist/tremezzo/235-245/243,4_cm_=_120_+_120.svg';

import SvgTremezzo248_01 from '@/common/svgs/custom/dist/tremezzo/245-275/248_cm_=_100_+_45_+_100.svg';
import SvgTremezzo248_02 from '@/common/svgs/custom/dist/tremezzo/245-275/248,4_cm_=_35_+_70_+_35_+_70_+_35.svg';
import SvgTremezzo248_03 from '@/common/svgs/custom/dist/tremezzo/245-275/248,4_cm_=_100_+_100_+_45.svg';
import SvgTremezzo251_01 from '@/common/svgs/custom/dist/tremezzo/245-275/251,8_cm_=_100_+_60_+_28,4_+_60.svg';
import SvgTremezzo253_01 from '@/common/svgs/custom/dist/tremezzo/245-275/253,4_cm_=_90_+_70_+_90.svg';
import SvgTremezzo253_02 from '@/common/svgs/custom/dist/tremezzo/245-275/253,4_cm_=_90_+_90_+_70.svg';
import SvgTremezzo261_01 from '@/common/svgs/custom/dist/tremezzo/245-275/261,8_cm_=_35_+_80_+_28,4_+_80_+_35.svg';
import SvgTremezzo263_01 from '@/common/svgs/custom/dist/tremezzo/245-275/263,4_cm_=_90_+_80_+_90.svg';
import SvgTremezzo263_02 from '@/common/svgs/custom/dist/tremezzo/245-275/263,4_cm_=_90_+_90_+_80.svg';
import SvgTremezzo263_03 from '@/common/svgs/custom/dist/tremezzo/245-275/263,4_cm_=_100_+_60_+_100.svg';
import SvgTremezzo263_04 from '@/common/svgs/custom/dist/tremezzo/245-275/263,4_cm_=_100_+_100_+_60.svg';
import SvgTremezzo268_01 from '@/common/svgs/custom/dist/tremezzo/245-275/268,4_cm_=_35_+_80_+_35_+_80_+_35.svg';
import SvgTremezzo271_01 from '@/common/svgs/custom/dist/tremezzo/245-275/271,8_cm_=_80_+_28,4_+_80_+_80.svg';
import SvgTremezzo271_02 from '@/common/svgs/custom/dist/tremezzo/245-275/271,8_cm_=_80_+_80_+_28,4_+_80.svg';
import SvgTremezzo271_03 from '@/common/svgs/custom/dist/tremezzo/245-275/271,8_cm_=_120_+_28,4_+_120.svg';
import SvgTremezzo271_04 from '@/common/svgs/custom/dist/tremezzo/245-275/271,8_cm_=_120_+_60_+_28,4_+_60.svg';
import SvgTremezzo273_01 from '@/common/svgs/custom/dist/tremezzo/245-275/273,4_cm_=_90_+_90_+_90.svg';
import SvgTremezzo273_02 from '@/common/svgs/custom/dist/tremezzo/245-275/273,4_cm_=_100_+_70_+_100.svg';
import SvgTremezzo273_03 from '@/common/svgs/custom/dist/tremezzo/245-275/273,4_cm_=_100_+_100_+_70.svg';

// NEW L1 
import SvgTremezzoL1_143_01 from '@/common/svgs/custom/dist/tremezzo/L1/143,4=1,7+35+70+35+1,7.svg';
import SvgTremezzoL1_143_02 from '@/common/svgs/custom/dist/tremezzo/L1/143,4=1,7+60+45+35+1,7.svg';
import SvgTremezzoL1_150_01 from '@/common/svgs/custom/dist/tremezzo/L1/150,1=1,7+120+28,4.svg';
import SvgTremezzoL1_150_02 from '@/common/svgs/custom/dist/tremezzo/L1/150,1=28,4+60+60+1,7.svg';
import SvgTremezzoL1_150_03 from '@/common/svgs/custom/dist/tremezzo/L1/150,1=28,4+120+1,7.svg';
import SvgTremezzoL1_151_01 from '@/common/svgs/custom/dist/tremezzo/L1/151,8=1,7+28,4+60+60+1,7.svg';
import SvgTremezzoL1_151_02 from '@/common/svgs/custom/dist/tremezzo/L1/151,8=1,7+28,4+120+1,7.svg';
import SvgTremezzoL1_151_03 from '@/common/svgs/custom/dist/tremezzo/L1/151,8=1,7+120+28,4+1,7.svg';
import SvgTremezzoL1_163_01 from '@/common/svgs/custom/dist/tremezzo/L1/163,4=1,7+100+60+1,7.svg';
import SvgTremezzoL1_163_02 from '@/common/svgs/custom/dist/tremezzo/L1/163,4=1,7+35+90+35+1,7.svg';
import SvgTremezzoL1_163_03 from '@/common/svgs/custom/dist/tremezzo/L1/163,4=1,7+70+90+1,7.svg';
import SvgTremezzoL1_163_04 from '@/common/svgs/custom/dist/tremezzo/L1/163,4=1,7+90+70+1,7.svg';
import SvgTremezzoL1_178_01 from '@/common/svgs/custom/dist/tremezzo/L1/178,4=1,7+45+70+60+1,7.svg';
import SvgTremezzoL1_180_01 from '@/common/svgs/custom/dist/tremezzo/L1/180,1=1,7+80+70+28,4.svg';
import SvgTremezzoL1_181_01 from '@/common/svgs/custom/dist/tremezzo/L1/181,8=1,7+80+70+24,8+1,7.svg';
import SvgTremezzoL1_183_01 from '@/common/svgs/custom/dist/tremezzo/L1/183,4=1,7+120+60+1,7.svg';
import SvgTremezzoL1_188_01 from '@/common/svgs/custom/dist/tremezzo/L1/188,4=1,7+90+35+60+1,7.svg';
import SvgTremezzoL1_193_01 from '@/common/svgs/custom/dist/tremezzo/L1/193,4=1,7+80+35+40+35+1,7.svg';
import SvgTremezzoL1_198_01 from '@/common/svgs/custom/dist/tremezzo/L1/198,4=1,7+80+35+40+35+1,7.svg';
import SvgTremezzoL1_201_01 from '@/common/svgs/custom/dist/tremezzo/L1/201,8=1,7+80+28,4+90+1,7.svg';
import SvgTremezzoL1_201_02 from '@/common/svgs/custom/dist/tremezzo/L1/201,8=1,7+100+28,4+70+1,7.svg';
import SvgTremezzoL1_203_01 from '@/common/svgs/custom/dist/tremezzo/L1/203.4=1,7+35+70+60+35+1,7.svg';
import SvgTremezzoL1_203_02 from '@/common/svgs/custom/dist/tremezzo/L1/203.4=1,7+120+80+1,7.svg';
import SvgTremezzoL1_208_01 from '@/common/svgs/custom/dist/tremezzo/L1/208.4=1,7+45+80+35+45+1,7.svg';
import SvgTremezzoL1_208_02 from '@/common/svgs/custom/dist/tremezzo/L1/208.4=1,7+90+35+45+35+1,7.svg';
import SvgTremezzoL1_211_01 from '@/common/svgs/custom/dist/tremezzo/L1/211.8=1,7+60+60+28,4+60+1,7.svg';
import SvgTremezzoL1_213_01 from '@/common/svgs/custom/dist/tremezzo/L1/213,4=1,7+35+70+70+35+1,7.svg';
import SvgTremezzoL1_213_02 from '@/common/svgs/custom/dist/tremezzo/L1/213,4=1,7+60+90+60+1,7.svg';
import SvgTremezzoL1_213_03 from '@/common/svgs/custom/dist/tremezzo/L1/213,4=1,7+120+90+1,7.svg';
import SvgTremezzoL1_218_04 from '@/common/svgs/custom/dist/tremezzo/L1/218,4=1,7+60+60+35+60+1,7.svg';
import SvgTremezzoL1_218_05 from '@/common/svgs/custom/dist/tremezzo/L1/218,4=1,7+100+35+80+1,7.svg';
import SvgTremezzoL1_221_01 from '@/common/svgs/custom/dist/tremezzo/L1/221,8=1,7+60+35+28,4+35+60+1,7.svg';
import SvgTremezzoL1_223_01 from '@/common/svgs/custom/dist/tremezzo/L1/223,4=1,7+60+100+60+1,7.svg';
import SvgTremezzoL1_223_02 from '@/common/svgs/custom/dist/tremezzo/L1/223,4=1,7+70+80+70+1,7.svg';
import SvgTremezzoL1_223_03 from '@/common/svgs/custom/dist/tremezzo/L1/223,4=1,7+90+35+60+35+1,7.svg';
import SvgTremezzoL1_228_01 from '@/common/svgs/custom/dist/tremezzo/L1/228,4=1,7+60+60+45+60+1,7.svg';
import SvgTremezzoL1_228_02 from '@/common/svgs/custom/dist/tremezzo/L1/228,4=1,7+90+45+45+45+1,7.svg';
import SvgTremezzoL1_228_03 from '@/common/svgs/custom/dist/tremezzo/L1/228,4=1,7+100+45+35+45+1,7.svg';
import SvgTremezzoL1_231_01 from '@/common/svgs/custom/dist/tremezzo/L1/231,8=1,7+60+70+35+28,4+35+1,7.svg';
import SvgTremezzoL1_233_01 from '@/common/svgs/custom/dist/tremezzo/L1/233,4=1,7+45+70+45+70+1,7.svg';
import SvgTremezzoL1_233_02 from '@/common/svgs/custom/dist/tremezzo/L1/233,4=1,7+100+35+60+35+1,7.svg';
import SvgTremezzoL1_241_01 from '@/common/svgs/custom/dist/tremezzo/L1/241,8=1,7+60+60+45+28,4+45+1,7.svg';
import SvgTremezzoL1_241_02 from '@/common/svgs/custom/dist/tremezzo/L1/241,8=1,7+70+70+35+28,4+35+1,7.svg';
import SvgTremezzoL1_243_01 from '@/common/svgs/custom/dist/tremezzo/L1/243,4=1,7+45+45+60+45+45+1,7.svg';
import SvgTremezzoL1_243_02 from '@/common/svgs/custom/dist/tremezzo/L1/243,4=1,7+60+12+60+1,7.svg';
import SvgTremezzoL1_243_03 from '@/common/svgs/custom/dist/tremezzo/L1/243,4=1,7+60+120+60+1,7.svg';
import SvgTremezzoL1_251_01 from '@/common/svgs/custom/dist/tremezzo/L1/251,8=1,7+60+70+45+28,4+45+1,7.svg';
import SvgTremezzoL1_251_02 from '@/common/svgs/custom/dist/tremezzo/L1/251,8=1,7+80+70+28,4+70+1,7.svg';
import SvgTremezzoL1_253_01 from '@/common/svgs/custom/dist/tremezzo/L1/253,4=1,7+80+45+80+45+1,7.svg';
import SvgTremezzoL1_253_02 from '@/common/svgs/custom/dist/tremezzo/L1/253,4=1,7+120+35+60+35+1,7.svg';

// New L2
import SvgTremezzoL2_203_01 from '@/common/svgs/custom/dist/tremezzo/L2/203,4=1,7+60+60+80+1,7.svg';
import SvgTremezzoL2_203_02 from '@/common/svgs/custom/dist/tremezzo/L2/203,4=1,7+60+80+60+1,7.svg';
import SvgTremezzoL2_213_01 from '@/common/svgs/custom/dist/tremezzo/L2/213,4=1,7+35+70+70+35+1,7.svg';
import SvgTremezzoL2_228_01 from '@/common/svgs/custom/dist/tremezzo/L2/228,4=1,7+35+60+60+70+1,7.svg';
import SvgTremezzoL2_230_01 from '@/common/svgs/custom/dist/tremezzo/L2/230,1=1,7+70+60+70+28,4.svg';
import SvgTremezzoL2_231_01 from '@/common/svgs/custom/dist/tremezzo/L2/231,8=1,7+60+70+28,4+70+1,7.svg';
import SvgTremezzoL2_231_02 from '@/common/svgs/custom/dist/tremezzo/L2/231,8=1,7+70+60+70+28,4+1,7.svg';
import SvgTremezzoL2_233_01 from '@/common/svgs/custom/dist/tremezzo/L2/233,4=1,7+70+90+70+1,7.svg';
import SvgTremezzoL2_238_01 from '@/common/svgs/custom/dist/tremezzo/L2/238,4=1,7+70+35+70+60+1,7.svg';
import SvgTremezzoL2_238_02 from '@/common/svgs/custom/dist/tremezzo/L2/238,4=1,7+80+60+35+60+1,7.svg';
import SvgTremezzoL2_241_01 from '@/common/svgs/custom/dist/tremezzo/L2/241,8=1,7+70+35+28,4+35+70+1,7.svg';
import SvgTremezzoL2_241_02 from '@/common/svgs/custom/dist/tremezzo/L2/241,8=1,7+90+60+28,4+60+1,7.svg';
import SvgTremezzoL2_243_01 from '@/common/svgs/custom/dist/tremezzo/L2/243,4=1,7+60+60+60+60+1,7.svg';
import SvgTremezzoL2_248_01 from '@/common/svgs/custom/dist/tremezzo/L2/248,4=1,7+45+60+35+60+45+1,7.svg';
import SvgTremezzoL2_248_02 from '@/common/svgs/custom/dist/tremezzo/L2/248,4=1,7+70+70+35+70+1,7.svg';
import SvgTremezzoL2_253_01 from '@/common/svgs/custom/dist/tremezzo/L2/253,4=1,7+35+60+60+60+35+1,7.svg';
import SvgTremezzoL2_253_02 from '@/common/svgs/custom/dist/tremezzo/L2/253,4=1,7+35+90+90+35+1,7.svg';
import SvgTremezzoL2_253_03 from '@/common/svgs/custom/dist/tremezzo/L2/253,4=1,7+45+80+80+45+1,7.svg';
import SvgTremezzoL2_253_04 from '@/common/svgs/custom/dist/tremezzo/L2/253,4=1,7+80+80+90+80+1,7.svg';
import SvgTremezzoL2_261_01 from '@/common/svgs/custom/dist/tremezzo/L2/261,8=1,7+45+70+28,4+70+45+1,7.svg';
import SvgTremezzoL2_263_01 from '@/common/svgs/custom/dist/tremezzo/L2/263,4=1,7+35+60+70+60+35+1,7.svg';
import SvgTremezzoL2_263_02 from '@/common/svgs/custom/dist/tremezzo/L2/263,4=1,7+60+70+70+60+1,7.svg';
import SvgTremezzoL2_268_01 from '@/common/svgs/custom/dist/tremezzo/L2/268,4=1,7+70+45+70+80+1,7.svg';

export const icons: any = {
  // general icons
  bath: SvgBath,
  tap: SvgTap,
  mirror: SvgMirror,
  basin: SvgBasin,
  furniture: SvgFurniture,
  heart: SvgHeart,
  shoppingBag: SvgShoppingBag,
  message: SvgMessage,
  tooltip: SvgTooltip,
  configurator: SvgConfigurator,

  // one up drawer
  oneup_drawer_open_1: SvgoneUpDrawerOpen,
  oneup_drawer_integrated_1: SvgoneUpDrawerOpenIntegrated,
  oneup_drawer_open_2: SvgoneUpDrawerOpen,
  oneup_drawer_integrated_2: SvgoneUpDrawerOpenIntegrated,
  oneup_drawer_open_3: SvgoneUpDrawerOpen,
  oneup_drawer_open_4: SvgoneUpDrawerOpen,

  // lax drawer
  lax_drawer_one: SvgLaxDrawerOpen,
  'lax_drawer_32-one': SvgLaxDrawerOpen,
  'lax_drawer_35-one': SvgLaxDrawerOpen,
  'lax_drawer_51-one': SvgLaxDrawerOpen,
  lax_drawer_integrated: SvgLaxDrawerOpenIntegrated,
  'lax_drawer_48-integrated': SvgLaxDrawerOpenIntegrated,
  'lax_drawer_51-integrated': SvgLaxDrawerOpenIntegrated,
  'lax_drawer_48-two': SvgLaxDrawerTwoOpen,
  'lax_drawer_55-two': SvgLaxDrawerTwoOpen,
  'lax_drawer_64-two': SvgLaxDrawerTwoOpen,

  // tremezzo drawer
  'tremezzo_drawer_32-1': SvgTremezzoDrawerOpen,
  'tremezzo_drawer_48-1': SvgTremezzoDrawerOpen,
  'tremezzo_drawer_48-2': SvgTremezzoDrawerOpenIntegrated,

  // aure
  'aure_front_configurationDRAWER': SvgAureOneFront,
  'aure_front_configurationSHELF+DRAWER': SvgAureOpenCompartment,
  'aure_front_configurationSIDEBYSIDE': SvgAureSideBySide,
  'aure_front_configurationDRAWER+DRAWER': SvgAureTwoFronts,

  // tremezzo
  '1_L. 63,4': SvgTremezzo63_01,
  '1_L. 73,4': SvgTremezzo73_01,
  '1_L. 83,4': SvgTremezzo83_01,

  '1_L. 93,4': SvgTremezzo93_01,
  '1_L. 98,4': SvgTremezzo98_01,
  '1_L. 103,4': SvgTremezzo103_01,
  '1_L. 108,4': SvgTremezzo108_02,
  '1_L. 108,4_2': SvgTremezzo108_01,

  '1_L. 118,4': SvgTremezzo118_01,
  '1_L. 118,4_2': SvgTremezzo118_02,
  '1_L. 123,4': SvgTremezzo123_02,
  '1_L. 123,4_2': SvgTremezzo123_01,
  '1_L. 128,4': SvgTremezzo128_01,
  '1_L. 128,4_2': SvgTremezzo128_02,

  '1_L. 138,4': SvgTremezzo138_01,
  '1_L. 138,4_2': SvgTremezzo138_02,
  '1_L. 143,4': SvgTremezzo143_02,
  '1_L. 143,4_2': SvgTremezzo143_01,
  '1_L. 143,4_3': SvgTremezzoL1_143_01,
  '1_L. 143,4_4': SvgTremezzoL1_143_02,
  '1_L. 148,4': SvgTremezzo148_01,
  '1_L. 150,1': SvgTremezzoL1_150_01,
  '1_L. 150,1_2': SvgTremezzoL1_150_02,
  '1_L. 150,1_3': SvgTremezzoL1_150_01,
  '1_L. 151,8': SvgTremezzo151_01,
  '1_L. 151,8_2': SvgTremezzoL1_151_01,
  '1_L. 151,8_3': SvgTremezzoL1_151_02,
  '1_L. 151,8_4': SvgTremezzoL1_151_03,
  '1_L. 158,4': SvgTremezzo158_01,
  '1_L. 158,4_2': SvgTremezzo158_02,
  '1_L. 163,4': SvgTremezzo163_01,
  '1_L. 163,4_2': SvgTremezzoL1_163_01,
  '1_L. 163,4_5': SvgTremezzoL1_163_02,
  '1_L. 163,4_4': SvgTremezzoL1_163_03,
  '1_L. 163,4_3': SvgTremezzoL1_163_04,
  '1_L. 168,4': SvgTremezzo168_02,
  '1_L. 168,4_2': SvgTremezzo168_01,
  '1_L. 171,8': SvgTremezzo171_02,
  '1_L. 171,8_2': SvgTremezzo171_01,

  '1_L. 178,4': SvgTremezzo178_01,
  '1_L. 178,4_2': SvgTremezzoL1_178_01,
  '1_L. 181,8': SvgTremezzo181_01,
  '1_L. 180,1': SvgTremezzoL1_180_01,
  '1_L. 180,1_2': SvgTremezzoL1_180_01,
  '1_L. 181,8_2': SvgTremezzoL1_181_01,
  '1_L. 183,4': SvgTremezzo183_01,
  '1_L. 183,4_3': SvgTremezzo183_02,
  '1_L. 183,4_4': SvgTremezzo183_03,
  '1_L. 183,4_5': SvgTremezzoL1_183_01,
  '1_L. 188,4': SvgTremezzo188_01,
  '1_L. 188,4_2': SvgTremezzoL1_188_01,
  '1_L. 191,8': SvgTremezzo191_01,
  '1_L. 191,8_2': SvgTremezzo191_02,
  '1_L. 191,8_3': SvgTremezzo191_03,

  '1_L. 193,4': SvgTremezzoL1_193_01,

  '1_L. 198,4': SvgTremezzo198_01,
  '1_L. 198,4_2': SvgTremezzoL1_198_01,
  '1_L. 201,8': SvgTremezzo201_01,
  '1_L. 201,8_2': SvgTremezzo201_02,
  '1_L. 201,8_3': SvgTremezzoL1_201_01,
  '1_L. 201,8_4': SvgTremezzoL1_201_02,
  '1_L. 203,4': SvgTremezzo203_03,
  '1_L. 203,4_2': SvgTremezzoL1_203_01,
  '1_L. 203,4_3': SvgTremezzoL1_203_01,
  '1_L. 203,4_4': SvgTremezzo203_04,

  '1_L. 208,4': SvgTremezzo208_01,
  '1_L. 208,4_2': SvgTremezzoL1_208_01,
  '1_L. 208,4_3': SvgTremezzoL1_208_02,
  
  '1_L. 211,8': SvgTremezzo211_04,
  '1_L. 211,8_2': SvgTremezzo211_03,
  '1_L. 211,8_3': SvgTremezzoL1_211_01,
  '1_L. 213,4': SvgTremezzo213_01,
  '1_L. 213,4_2': SvgTremezzoL1_213_01,
  '1_L. 213,4_3': SvgTremezzoL1_213_02,
  '1_L. 213,4_4': SvgTremezzoL1_213_03,

  '1_L. 218,4': SvgTremezzo218_02,
  '1_L. 218,4_2': SvgTremezzoL1_218_04,
  '1_L. 218,4_3': SvgTremezzoL1_218_05,
  '1_L. 221,8': SvgTremezzo221_02,
  '1_L. 221,8_2': SvgTremezzo221_03,
  '1_L. 221,8_3': SvgTremezzoL1_221_01,
  '1_L. 223,4': SvgTremezzo223_01,
  '1_L. 223,4_2': SvgTremezzoL1_223_01,
  '1_L. 223,4_3': SvgTremezzoL1_223_02,
  '1_L. 223,4_4': SvgTremezzoL1_223_03,

  '1_L. 228,4': SvgTremezzo228_02,
  '1_L. 228,4_2': SvgTremezzoL1_228_01,
  '1_L. 228,4_3': SvgTremezzoL1_228_02,
  '1_L. 228,4_4': SvgTremezzoL1_228_03,
  '1_L. 231,8': SvgTremezzo231_01,
  '1_L. 231,8_2': SvgTremezzoL1_231_01,
  '1_L. 233,4': SvgTremezzo233_01,
  '1_L. 233,4_2': SvgTremezzoL1_233_02,
  '1_L. 233,4_3': SvgTremezzoL1_233_01,

  '1_L. 241,8': SvgTremezzo241_04,
  '1_L. 241,8_2': SvgTremezzo241_05,
  '1_L. 241,8_3': SvgTremezzoL1_241_01,
  '1_L. 241,8_4': SvgTremezzoL1_241_02,
  '1_L. 243,4': SvgTremezzo243_01,
  '1_L. 243,4_3': SvgTremezzo243_02,
  '1_L. 243,4_4': SvgTremezzo243_04,
  '1_L. 243,4_5': SvgTremezzoL1_243_01,
  '1_L. 243,4_6': SvgTremezzoL1_243_03,
  '1_L. 243,4_7': SvgTremezzo243_05,

  '1_L. 251,8': SvgTremezzo251_01,
  '1_L. 251,8_2': SvgTremezzoL1_251_01,
  '1_L. 251,8_3': SvgTremezzoL1_251_02,
  '1_L. 253,4': SvgTremezzo253_01,
  '1_L. 253,4_2': SvgTremezzoL1_253_01,
  '1_L. 253,4_3': SvgTremezzoL1_253_02,
  '1_L. 271,8': SvgTremezzo271_04,
  '1_L. 273,4': SvgTremezzo273_01,

  '2_L. 123,4': SvgTremezzo123_01,
  '2_L. 143,4': SvgTremezzo143_01,

  '2_L. 151,8': SvgTremezzo151_01,
  '2_L. 158,4': SvgTremezzo158_01,
  '2_L. 163,4': SvgTremezzo163_01,
  '2_L. 168,4': SvgTremezzo168_01,
  '2_L. 171,8': SvgTremezzo171_01,

  '2_L. 178,4': SvgTremezzo178_01,
  '2_L. 178,4_2': SvgTremezzo178_02,
  '2_L. 183,4': SvgTremezzo183_03,
  '2_L. 183,4_3': SvgTremezzo183_01,

  '2_L. 188,4': SvgTremezzo188_01,
  '2_L. 188,4_2': SvgTremezzo188_02,
  '2_L. 191,8': SvgTremezzo191_02,

  '2_L. 198,4': SvgTremezzo198_01,
  '2_L. 198,4_2': SvgTremezzo198_02,
  '2_L. 203,4': SvgTremezzo203_04,
  '2_L. 203,4_2': SvgTremezzo203_03,
  '2_L. 203,4_3': SvgTremezzo203_02,
  '2_L. 203,4_4': SvgTremezzoL2_203_02,
  '2_L. 203,4_5': SvgTremezzoL2_203_01,

  '2_L. 208,4': SvgTremezzo208_01,
  '2_L. 208,4_2': SvgTremezzo208_02,
  '2_L. 211,8': SvgTremezzo211_01,
  '2_L. 211,8_2': SvgTremezzo211_02,
  '2_L. 211,8_3': SvgTremezzo211_03,
  '2_L. 213,4': SvgTremezzo213_01,
  '2_L. 213,4_2': SvgTremezzoL2_213_01,
  '2_L. 213,4_3': SvgTremezzoL2_213_01,
  '2_L. 213,4_4': SvgTremezzoL2_213_01,

  '2_L. 218,4': SvgTremezzo218_02,
  '2_L. 218,4_2': SvgTremezzo218_01,
  '2_L. 221,8': SvgTremezzo221_01,
  '2_L. 223,4': SvgTremezzo223_01,
  '2_L. 223,4_2': SvgTremezzo223_02,

  '2_L. 228,4': SvgTremezzo228_01,
  '2_L. 228,4_2': SvgTremezzo228_02,
  '2_L. 228,4_3': SvgTremezzoL2_228_01,
  '2_L. 228,4_4': SvgTremezzoL2_228_01,
  '2_L. 230,1': SvgTremezzoL2_230_01,
  '2_L. 230,1_3': SvgTremezzoL2_230_01,
  '2_L. 231,8': SvgTremezzo231_02,
  '2_L. 231,8_2': SvgTremezzoL2_231_01,
  '2_L. 231,8_3': SvgTremezzoL2_231_02,
  '2_L. 233,4': SvgTremezzo233_01,
  '2_L. 233,4_2': SvgTremezzo233_02,
  '2_L. 233,4_3': SvgTremezzoL2_233_01,

  '2_L. 238,4': SvgTremezzo238_01,
  '2_L. 238,4_2': SvgTremezzo238_02,
  '2_L. 238,4_3': SvgTremezzoL2_238_01,
  '2_L. 238,4_4': SvgTremezzoL2_238_02,

  '2_L. 241,8': SvgTremezzo241_02,
  '2_L. 241,8_2': SvgTremezzo241_03,
  '2_L. 241,8_3': SvgTremezzo241_01,
  '2_L. 241,8_4': SvgTremezzoL2_241_01,
  '2_L. 241,8_5': SvgTremezzoL2_241_02,
  '2_L. 243,4': SvgTremezzo243_01,
  '2_L. 243,4_3': SvgTremezzo243_02,
  '2_L. 243,4_4': SvgTremezzo243_03,
  '2_L. 243,4_5': SvgTremezzo243_05,
  '2_L. 243,4_6': SvgTremezzoL2_243_01,

  '2_L. 248,4': SvgTremezzo248_01,
  '2_L. 248,4_2': SvgTremezzo248_03,
  '2_L. 248,4_3': SvgTremezzo248_02,
  '2_L. 248,4_4': SvgTremezzoL2_248_01,
  '2_L. 248,4_5': SvgTremezzoL2_248_02,
  '2_L. 253,4': SvgTremezzo253_01,
  '2_L. 253,4_2': SvgTremezzo253_02,
  '2_L. 253,4_3': SvgTremezzoL2_253_01,
  '2_L. 253,4_4': SvgTremezzoL2_253_02,
  '2_L. 253,4_5': SvgTremezzoL2_253_04,
  '2_L. 253,4_6': SvgTremezzoL2_253_01,

  '2_L. 261,8': SvgTremezzo261_01,
  '2_L. 261,8_2': SvgTremezzoL2_261_01,
  '2_L. 263,4': SvgTremezzo263_01,
  '2_L. 263,4_2': SvgTremezzo263_02,
  '2_L. 263,4_3': SvgTremezzo263_03,
  '2_L. 263,4_4': SvgTremezzo263_04,
  '2_L. 263,4_5': SvgTremezzoL2_263_01,
  '2_L. 263,4_6': SvgTremezzoL2_263_02,

  '2_L. 268,4': SvgTremezzo268_01,
  '2_L. 268,4_2': SvgTremezzoL2_268_01,
  '2_L. 271,8': SvgTremezzo271_02,
  '2_L. 271,8_2': SvgTremezzo271_01,
  '2_L. 271,8_3': SvgTremezzo271_03,
  '2_L. 273,4': SvgTremezzo273_01,
  '2_L. 273,4_3': SvgTremezzo273_02,
  '2_L. 273,4_4': SvgTremezzo273_03,
};

export const iconByKey = (key: string) => {
  let result = null;

  for (const icon in icons) {
    if (icon === key) {
      result = icons[icon];
    }
  }

  return result;
};
